export function bytesToString(b: number): string {
  if (b < 100) return `${b} B`;
  const s = ['B', 'KB', 'MB', 'GB'];
  const i = parseInt(Math.floor(Math.log(b) / Math.log(1000)).toString(), 10);
  return `${(b / (1000 ** i)).toPrecision(3)} ${s[i]}`;
}

export default {
  bytesToString,
};
